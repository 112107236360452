<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Stock Request</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="productStockInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Venue</label>
                  <model-list-select
                    :list="venues"
                    v-model="form.venue_id"
                    option-value="id"
                    option-text="title"
                    placeholder="Select Venue"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.venue_id">{{
                    errors.venue_id[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Product</label>
                      <v2-select
                        v-model="form.product_id"
                        :options="products"
                        :reduce="(products) => products.id"
                        @input="getProductUnitParams"
                        label="name"
                      >
                      </v2-select>
                      <small class="text-danger" v-if="errors.product_id">{{
                        errors.product_id[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Stock</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Add Stock"
                        v-model="form.stock"
                      />
                      <small class="text-danger" v-if="errors.stock">{{
                        errors.stock[0]
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <v2-select
                    v-model="form.unit_id"
                    :options="product_units_data"
                    :reduce="(product_units_data) => product_units_data.unit_id"
                    label="unit_name"
                  >
                  </v2-select>
                  <small class="text-danger" v-if="errors.unit_id">{{
                    errors.unit_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <select class="form-control" v-model="form.status">
                    <option value="">Select Status</option>
                    <option value="IN">IN</option>
                    <option value="OUT">OUT</option>
                  </select>
                  <small class="text-danger" v-if="errors.status">{{
                    errors.status[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import master from "../../helpers/master";

export default {
  name: "FormProductStock",
  extends: master,
  data() {
    return {
      name: "Form Product Stock",
      form: {
        product_id: "",
        venue_id: "",
        stock: "",
        status: "",
        unit_id: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
      venues: [],
      products: [],
      product_units_data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams() {
      this.form.unit_id = "";
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.form.product_id,
          },
        })
        .then((result) => {
          this.product_units_data = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    productStockInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "product_stock/store", this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your Product Stock has been created!");
          this.$router.push({ name: "ProductStock" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
