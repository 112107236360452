import axios from "axios";
import { apiGet } from "../services/api";
export default {
    methods: {
        getProduct() {
            apiGet("product/all/minimal")
                .then((result) => {
                    this.products = result.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getUnit() {
            apiGet("unit")
                .then((result) => {
                    this.units = result.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getProductUnit() {
            apiGet("product_unit")
                .then((result) => {
                    this.product_units_data = result.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getVenue() {
            apiGet("venue")
                .then((result) => {
                    this.venues = result.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getProductUnitParams() {
            axios
                .get(this.$store.state.api + "product_unit", {
                    headers: {
                        Authorization: this.$store.state.token,
                    },
                    params: {
                        product_id: this.form.product_id,
                    },
                })
                .then((result) => {
                    this.product_units_data = result.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
}